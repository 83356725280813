import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { calculateResponsiveSize } from "helpers/calculateResponsiveSize";
import { mediaMax } from "helpers/mediaQueries";
import Logotype from "icons/logo.svg";
import InstagramIcon from "icons/instagram-icon-white.svg";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-beetwen;
  padding: 6vh ${calculateResponsiveSize(60)} 5vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow: auto;
  background: linear-gradient(0deg, #d77e5d 14.58%, #3f62ae 100%);

  &.menu {
  }

  &.menu-enter {
    opacity: 0;
  }

  &.menu-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
  }

  &.menu-exit {
    opacity: 1;
  }

  &.menu-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
  ${mediaMax("tabletLandscape")} {
    height: 100dvh;
    overflow: auto;
    padding: 20px;
  }
`;

export const Nav = styled.nav`
  padding: ${calculateResponsiveSize(180)} ${calculateResponsiveSize(40)}
    ${calculateResponsiveSize(30)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* height: ${calculateResponsiveSize(460)}; */
  ${mediaMax("desktopSm")} {
    padding: 40px 0;
    height: 80vh;
  }
  ${mediaMax("tablet")} {
    align-items: flex-start;
    text-align: left;
    margin: auto 0;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0 auto;
`;

export const Logo = styled(Link)`
  display: block;
  flex-shrink: 0;
  width: ${calculateResponsiveSize(286)};
  height: ${calculateResponsiveSize(172)};
  background-image: url(${Logotype});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.1);
  }
  ${mediaMax("desktopSm")} {
    width: 214px;
    height: 129px;
    background-size: cover;
  }
  ${mediaMax("tablet")} {
    margin-top: 20px;
    width: 179px;
    height: 108px;
  }
`;

export const MenuButtonWrapper = styled.div`
  position: absolute;
  top: ${calculateResponsiveSize(60)};
  right: ${({ $scrollBarWidth }) =>
    $scrollBarWidth
      ? `calc(${calculateResponsiveSize(60)} + ${$scrollBarWidth}px)`
      : `${calculateResponsiveSize(60)}`};
  ${mediaMax("desktopSm")} {
    top: 40px;
    right: 20px;
  }
  ${mediaMax("tablet")} {
    top: 20px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: auto;
  ${mediaMax("tabletLandscape")} {
    padding: 0;
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

export const Copyright = styled.div`
  /* font-weight: 600; */
  font-size: ${calculateResponsiveSize(18)};
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fcf4e8;
  font-family: "proxima_nova_semibold", san-serif;
  ${mediaMax("desktopSm")} {
    font-size: 12px;
  }
  ${mediaMax("tabletLandscape")} {
    order: 2;
    font-size: 10px;
    letter-spacing: 1px;
  }
`;

export const SocialLink = styled.a`
  width: ${calculateResponsiveSize(24)};
  height: ${calculateResponsiveSize(24)};
  background: url(${InstagramIcon}) no-repeat;
  transition: opacity 0.5s;
  background-size: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  ${mediaMax("desktopSm")} {
    width: 24px;
    height: 24px;
  }
  ${mediaMax("tabletLandscape")} {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    order: 3;
    width: 18px;
    height: 18px;
    margin-top: -4px;
  }
`;

export const Legal = styled.div`
  display: flex;
  align-items: center;
  ${mediaMax("tabletLandscape")} {
    width: 100%;
    order: 1;
  }
  ${mediaMax("tablet")} {
    justify-content: space-between;
  }
`;

export const LegalLink = styled(Link)`
  margin-left: ${calculateResponsiveSize(36)};
  font-size: ${calculateResponsiveSize(18)};
  line-height: ${calculateResponsiveSize(22)};
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "proxima_nova_semibold", san-serif;
  &:hover {
    opacity: 0.5;
  }
  ${mediaMax("desktopSm")} {
    font-size: 12px;
  }
  ${mediaMax("tabletLandscape")} {
    margin: 0 25px 34px 0;
    font-size: 10px;
    letter-spacing: 1px;
    &:last-child {
      margin-right: 0;
    }
  }
  ${mediaMax("tablet")} {
    margin-right: 0;
  }
`;

export const NavSublinks = styled.div`
  /* width: 40vw; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s linear;
  ${mediaMax("tabletLandscape")} {
    width: 80vw;
  }
  ${mediaMax("tablet")} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const NavItemSublinkInner = styled.span`
  position: relative;
  font-size: ${calculateResponsiveSize(18)};
  line-height: 2;
  letter-spacing: ${calculateResponsiveSize(1.8)};
  color: #ffffff;
  text-transform: uppercase;
  font-family: "proxima_nova_semibold", san-serif;
  margin: 0 ${calculateResponsiveSize(15)};
  &:before {
    content: "/";
    display: none;
    position: absolute;
    right: ${calculateResponsiveSize(-18)};
    top: 0;
    font-size: ${calculateResponsiveSize(18)};
    line-height: 2;
  }
  &.show-divider:before {
    display: inline-block;
  }
  &::after {
    content: "";
    display: block;
    height: ${calculateResponsiveSize(1)};
    background: #fff;
    width: 0;
    transition: width 1s;
  }
  ${mediaMax("desktopSm")} {
    &,
    &:before {
      font-size: 18px;
      line-height: 26px;
    }
  }
  ${mediaMax("tablet")} {
    font-size: 14px;
    letter-spacing: 1.4px;
    &.show-divider:before {
      display: none;
    }
  }
`;

export const NavItemSublinkStyles = css`
  display: flex;
  align-items: center;
  margin: ${calculateResponsiveSize(8)} 0;

  &:hover {
    ${NavItemSublinkInner} {
      &:after {
        width: 100%;
      }
    }
  }
  ${mediaMax("tablet")} {
    margin-bottom: 15px;
  }
`;

export const NavItemSublink = styled(Link)`
  ${NavItemSublinkStyles};
  ${mediaMax("tabletLandscape")} {
    pointer-events: none;
  }
`;

export const NavItemSubAnchor = styled(AnchorLink)`
  ${NavItemSublinkStyles};
`;

export const AdditionalMenu = styled.div`
  display: flex;
  /* position: absolute;
  bottom: 17vh;
  left: 50%;
  transform: translateX(-50%); */

  position: relative;
  justify-content: center;
  ${mediaMax("tabletLandscape")} {
    position: relative;
    margin: auto 0 30px;
    transform: none;
    left: auto;
    bottom: auto;
    justify-content: space-between;
  }
`;
export const AdditionalLink = styled(Link)`
  margin-right: ${calculateResponsiveSize(50)};
  font-size: ${calculateResponsiveSize(30)};
  line-height: ${calculateResponsiveSize(36)};
  letter-spacing: 0.1em;
  color: #ffffff;
  font-family: "caslon_semibold", san-serif;
  text-transform: uppercase;
  font-variant: all-small-caps;
  &:last-child {
    margin-right: 0;
  }
  ${mediaMax("desktopSm")} {
    font-size: 26px;
  }
  ${mediaMax("tablet")} {
    margin-right: 0;
  }
  ${mediaMax("tablet")} {
    font-size: 18px;
    letter-spacing: 1.8px;
  }
  ${mediaMax("phoneLarge")} {
    font-size: 16px;
  }
  ${mediaMax("phone")} {
    font-size: 15px;
  }
`;
export const AnimatimateBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  z-index: -1;
`;

export const NavItemWrapper = styled.div`
  margin-bottom: ${calculateResponsiveSize(60)};
  &:hover {
    ${NavSublinks} {
      max-height: ${calculateResponsiveSize(100)};
    }
  }
  &:first-child ${NavSublinks} {
    width: 40vw;
  }
  &:nth-child(2) ${NavSublinks} {
    width: 40vw;
  }

  &:last-child {
    margin-bottom: 0;
  }
  ${mediaMax("desktopSm")} {
    &:first-child ${NavSublinks} {
      width: 70vw;
    }
    &:hover {
      ${NavSublinks} {
        max-height: 0;
      }
    }

    ${NavSublinks} {
      &.open {
        max-height: 100px;
      }
    }
  }
  ${mediaMax("tabletLandscape")} {
    ${NavSublinks} {
      &.open {
        ${NavItemSublink} {
          pointer-events: auto;
        }
      }
    }
  }
  ${mediaMax("tablet")} {
    margin-bottom: 14px;
    ${NavSublinks} {
      &.open {
        max-height: 400px;
      }
    }
  }
`;
export const NavItemLink = styled(Link)`
  font-size: ${calculateResponsiveSize(72)};
  line-height: 1;
  letter-spacing: ${calculateResponsiveSize(-1)};
  color: #ffffff;
  font-weight: 400;
  ${mediaMax("desktopSm")} {
    pointer-events: ${({ $mobileClickableState }) =>
      $mobileClickableState ? "auto" : "none"};
    font-size: 56px;
  }
  ${mediaMax("tablet")} {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.72px;
  }
`;
